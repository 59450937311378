<template>
  <b-container fluid>
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="6">
        <h1>{{ $route.params.id ? 'Edit Location' : 'New Location' }}</h1>
        <hr>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Name"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'name_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="location.name[language.code]"
                  required
                ></b-form-input>
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="City"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model.number="location.city_id"
                  class="mb-3"
                  required
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(cities)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Leaders"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-table
                  responsive
                  hover
                  :fields="leaderFields"
                  :items="leaders"
                >
                  <template #cell(avatar)="data">
                    <b-img-lazy
                      :src="data.item.avatar
                        ? $config.cdnUrl + data.item.avatar.src
                        : data.item.weuser.avatarUrl"
                      thumbnail
                      style="width:75px;"
                    />
                  </template>
                  <template #cell(remove)="data">
                    <b-link
                      @click.prevent="removeLeader(data.item.id)"
                    >
                      <i
                        class="fas fa-minus-circle fa-lg"
                        style="color:red;"
                        title="Delete"
                      />
                    </b-link>
                  </template>
                </b-table>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Add Leaders"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-input
                  id="user-search"
                  v-model="searchInput"
                  placeholder="Start Typing a Username"
                  @input="searchUserInput"
                />
                <b-popover
                  target="user-search"
                  placement="bottom"
                  triggers="focus"
                >
                  <div v-if="searchInput.length >= 2">
                    <div
                      v-if="searchResults.length"
                      style="max-height:300px;overflow-y:scroll;"
                    >
                      <p
                        v-for="item in searchResults"
                        :key="item.id"
                      >
                        <b-img-lazy
                          style="width:35px;height:35px;margin-right:5px;"
                          rounded
                          :src="item.avatar
                            ? $config.cdnUrl + item.avatar.src
                            : item.weuser.avatarUrl"
                        />
                        <span>
                          <b-link
                            href="#"
                            @click.prevent="addLeader(item)"
                          >{{ item.nickname }}</b-link>
                        </span>
                      </p>
                    </div>
                    <p v-else>Please type a valid username to show results</p>
                  </div>
                  <p v-else>Type a name to search</p>
                </b-popover>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Location Instructions"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'instructions_' + language.id"
                md="6"
              >
                <b-form-textarea
                  v-model="location.instructions[language.code]"
                  class="form-control"
                  rows="3"
                />
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Sponsors"
                label-size="lg"
                label-class="font-weight-bold pt-0"
              >
                <b-form-select
                  v-model="selectedSponsors"
                  class="mb-3"
                  multiple
                  text-field="correctedName"
                  value-field="id"
                  :options="englishName(sponsors)"
                />
                <b-row>
                  <b-col>
                    <b-link
                      @click.prevent="selectedSponsors = []"
                    >Clear All</b-link>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Short Address"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'short_address_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="location.short_address[language.code]"
                  class="form-control"
                  rows="3"
                  required
                ></b-form-input>
                {{ language.name }}
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Full Address"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                v-for="language in languages"
                :key="'full_address_' + language.id"
                md="6"
              >
                <b-form-input
                  v-model="location.full_address[language.code]"
                  class="form-control"
                  required
                ></b-form-input>
                {{language.name}}
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group label="Set Map Markers">
                <b-row>
                  <b-col>
                    <b-form-input
                      id="addressinput"
                      v-model="address"
                      :required="!location.latitude || !location.longitude"
                      :disabled="mapInputDisabled"
                      :placeholder="mapInputDisabled
                        ? 'This location already has coordinates'
                        : 'Please type an address to find on map'"
                      @input="inputAddress"
                    ></b-form-input>
                    <b-popover
                      ref="locationPopover"
                      target="addressinput"
                      placement="bottom"
                      triggers="focus"
                    >
                      <div v-if="address.length >= 3">
                        <div v-if="results.length">
                          <p
                            v-for="(item, index) in results"
                            :key="index"
                          >
                            <span>
                              <b-link
                                href="#"
                                @click.prevent="selectAddress(item)"
                              >{{ item.name }}</b-link>
                            </span>
                            <br>
                            <span>
                              <small>{{ item.address }}</small>
                            </span>
                          </p>
                        </div>
                        <p v-else>No results found, try again</p>
                      </div>
                      <p v-else>Type an address to search</p>
                    </b-popover>
                  </b-col>
                  <b-col v-if="mapInputDisabled" md="3">
                    <b-btn @click="toggleMapInput">Reset</b-btn>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Coordinates">
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="location.latitude"
                      disabled
                    ></b-form-input>Latitude
                  </b-col>
                  <b-col>
                    <b-form-input
                      v-model="location.longitude"
                      disabled
                    ></b-form-input>Longitude
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <el-amap
            class="mb-3"
            style="width:100%;height:150px;"
            vid="amap"
            :zoom="zoom"
            :center="center"
          >
            <el-amap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :draggable="!mapInputDisabled"
              :events="marker.events"
              @dragging="onDragMarker"
            ></el-amap-marker>
          </el-amap>
          <b-button
            type="submit"
            variant="primary"
          >Submit</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import debounce from 'lodash.debounce'
import { englishName } from '@/common'
import { makeGetRequest } from '@/util'

export default {
  name: 'CreateEditLocation',
  data() {
    return {
      location: {
        city_id: 1,
        name: {},
        description: {},
        instructions: {},
        contact_name: null,
        contact_phone: null,
        short_address: {},
        full_address: {},
        latitude: null,
        longitude: null
      },
      address: '',
      results: [],
      center: [121.59996, 31.197646],
      zoom: 8,
      leaderFields: [
        { key: 'id', label: '#' },
        { key: 'avatar', label: 'Image' },
        { key: 'nickname', label: 'Nickname' },
        'remove'
      ],
      leaders: [],
      searchInput: '',
      searchResults: [],
      sponsors: [],
      languages: [],
      cities: [],
      selectedSponsors: [],
      markers: [],
      mapInputDisabled: false
    }
  },
  methods: {
    englishName,
    async onSubmit(e) {
      e.preventDefault()
      const { location, selectedSponsors: sponsors, leaders: leadersArr } = this
      const leaders = leadersArr.map((s) => s.id)
      const locationId = this.$route.params.id
      location.leaders = leaders
      location.sponsors = sponsors
      try {
        let url = `${this.$config.apiUrl}/locations`
        let method = 'post'
        if (locationId) {
          url += `/${locationId}`
          method = 'put'
        }
        await this.axios({
          method,
          url,
          data: location
        })
        if (!locationId) {
          this.$root.locations.push(location)
        }
        this.$router.replace('/admin/locations')
      } catch (error) {
        // console.log(error)
      }
    },
    searchUserInput: debounce(function (value) { // eslint-disable-line
      if (value.length >= 2) {
        this.searchQuery(value)
      }
    }, 500),
    async searchQuery (value) {
      try {
        const res = await this.axios.get(`${this.$config.apiUrl}/users/search?nickname=${value}&include=weuser,avatar`)
        this.searchResults = res.data
      } catch (error) {
        // console.log(error)
      }
    },
    addLeader (leader) {
      if (!this.leaders.find((i) => i.id === leader.id)) {
        this.leaders.push(leader)
      }
      this.searchResults = []
      this.searchInput = ''
    },
    removeLeader (id) {
      this.leaders = this.leaders.filter((item) => id !== item.id)
    },
    inputAddress: debounce(function (value) { // eslint-disable-line
      if (value.length >= 3) {
        this.geocodingSearch(value)
      }
    }, 500),
    async geocodingSearch (value) {
      try {
        const url = `https://restapi.amap.com/v3/place/text?key=${this.$config.aMapKey}&keywords=${value}&offset=3`
        const res = await this.axios.get(url, { headers: '' })
        if (res.data.status !== '1') throw res.data.status
        this.results = res.data.pois
      } catch (error) {
        // console.log(error)
      }
    },
    selectAddress (item) {
      const formattedLocation = item.location.split(',')
      const longitude = formattedLocation[0]
      const latitude = formattedLocation[1]
      this.location.longitude = parseFloat(longitude)
      this.location.latitude = parseFloat(latitude)
      this.setMapOptions(latitude, longitude)
      this.$refs.locationPopover.$emit('close')
    },
    setMapOptions (latitude, longitude) {
      this.center = [parseFloat(longitude), parseFloat(latitude)]
      this.zoom = 14
      this.markers = [
        {
          position: [parseFloat(longitude), parseFloat(latitude)],
          events: {
            dragging: (e) => {
              this.onDragMarker(e)
            }
          }
        }
      ]
    },
    onDragMarker ({ lnglat: { lng, lat } }) {
      this.address = ''
      this.location.longitude = lng
      this.location.latitude = lat
    },
    toggleMapInput () {
      this.mapInputDisabled = !this.mapInputDisabled
    },
    async prepareData () {
      const [cities, languages, sponsors] = await Promise.all([
        makeGetRequest(`${this.$config.apiUrl}/cities`),
        makeGetRequest(`${this.$config.apiUrl}/languages`),
        makeGetRequest(`${this.$config.apiUrl}/sponsors`)
      ])
      this.cities = cities
      this.languages = languages
      this.sponsors = sponsors
    }
  },
  async created () {
    try {
      await this.prepareData()
      if (this.$route.params.id) {
        const url = `${this.$config.apiUrl}/locations/${this.$route.params.id}?include=leaders,leaders.weuser,leaders.avatar,sponsors`
        const { leaders, sponsors, ...location } = await makeGetRequest(url)
        this.location = location
        this.leaders = leaders
        this.selectedSponsors = sponsors.map((s) => s.id)
        this.setMapOptions(location.latitude, location.longitude)
        this.mapInputDisabled = true
      }
    } catch (error) {
      // console.error(error)
    }
  }
}
</script>

<style>
</style>
